<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-25 11:10:14
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-25 18:59:04
-->
<template>
  <div class="x-bar">
    <div :id="id" :option="option"></div>
  </div>
</template>
<script>
// 引入highCharts模块
import HighCharts from 'highcharts'
 
export default {
  data() {
    return{
      
    }
  },
  props: {
    id: {
      type: String
    },
    option: {
      type: Object
    }
  },
  mounted() {//钩子函数挂载时实例化这个图表
    this.getCharts()
  },
  methods: {
    getCharts() {
      HighCharts.chart(this.id,this.option)
    }
  }
}
</script>
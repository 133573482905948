<template>
  <a-card :bordered="false">
    <div class="cus_sum">
      <a-form-model :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
        <a-row :gutter="4">
          <a-col :xxl="4" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="事务所名称">
              <a-input v-model.trim="page.firmName" placeholder="请输入事务所名称" :max-length="50" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="6" :md="8" :sm="12">
            <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
              <a-button @click="getData(0)" type="primary" icon="search">查询</a-button>
              <a-button @click="getData(1)" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <downLoad
          method="post"
          api="/api/base/system/report/excelReportTurnoverSales"
          :params="page"
          @downLoadDone="downLoadDone"
          @startDownLoad="startDownLoad"
          :isConfirmLoading="tableLoading"
          name="事务所交易额统计.xls"
        >统计导出</downLoad
        >
<!--        <downLoad-->
<!--          method="get"-->
<!--          api="/api/customer/customer/customerInfo/orderExcelOExport"-->
<!--          :params="getCusIds"-->
<!--          name="客户订单统计.xls"-->
<!--          >统计导出-->
<!--        </downLoad>-->
<!--        <downLoad method="get" api="/api/customer/customer/customerInfo/orderExcelExport" name="客户订单汇总统计.xls"-->
<!--          >客户订单汇总统计导出-->
<!--        </downLoad>-->
<!--        <downLoad method="get" api="/api/customer/customer/customerInfo/cusExcelExport" name="客户预算模板.xls"-->
<!--          >客户预算模板导出-->
<!--        </downLoad>-->
<!--        <a-upload name="file" :action="uploadUrl" :headers="headers" :showUploadList="false" @change="upProductChange">-->
<!--          <a-button icon="upload" type="default">客户预算导入</a-button>-->
<!--        </a-upload>-->
      </div>
      <a-table
        :loading="tableLoading"
        :data-source="tableData"
        bordered
        :pagination="page"
        :columns="columns"
        @change="changeTable"
        :rowClassName="rowClassName"
        :rowKey="(record) => record.id"
        :row-selection="{ type: 'radio',fixed: true, selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <template slot="category">
          <p class="borber">销售目标</p>
          <p class="borber">实际金额</p>
          <p class="borber-bnt">达成率</p>
        </template>
        <template slot="subtotal" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusSubtotal != null && row.cusSubtotal != 0">{{ row.cusSubtotal | formatMoney  }}</span>
            <span v-if="row.cusSubtotal == null || row.cusSubtotal == 0">-</span>
          </p>
          <p class="borber">
            <span v-if="row.subtotal != null && row.subtotal != 0">{{ row.subtotal | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusSubtotal != 0 && row.cusJanuary != null"
              >{{ ((row.subtotal / row.cusSubtotal) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusSubtotal == 0 || row.cusJanuary == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="january" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusJanuary != null && row.cusJanuary != 0 ">{{ row.cusJanuary  | formatMoney  }}</span>
            <span v-if="row.cusJanuary == null || row.cusJanuary == 0 ">-</span
            ><br />
          </p>
          <p class="borber">
            <span v-if="row.january != null && row.january != 0">{{ row.january | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span class="enzosp" v-if="row.cusJanuary != 0 && row.cusJanuary != null"
              >{{ ((row.january / row.cusJanuary) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusJanuary == 0 || row.cusJanuary == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="february" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusFebruary != null && row.cusFebruary != 0 ">{{ row.cusFebruary  | formatMoney  }}</span>
            <span v-if="row.cusFebruary == null || row.cusFebruary == 0">-</span>
          </p>
          <p class="borber">
            <span v-if="row.february != null && row.february != 0">{{ row.february | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusFebruary != 0 && row.cusFebruary != null"
              >{{ ((row.february / row.cusFebruary) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusFebruary == 0 || row.cusFebruary == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="march" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusMarch != null  && row.cusMarch != 0">{{ row.cusMarch  | formatMoney  }}</span>
            <span v-if="row.cusMarch == null  || row.cusMarch == 0">-</span>
          </p>
          <p class="borber">
            <span v-if="row.march != null && row.march != 0">{{ row.march | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusMarch != 0 && row.cusMarch != null"
              >{{ ((row.march / row.cusMarch) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusMarch == 0 || row.cusMarch == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="april" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusApril != null && row.cusApril != 0">{{ row.cusApril | formatMoney  }}</span>
            <span v-if="row.cusApril == null || row.cusApril == 0">-</span>
          </p>
          <p class="borber">
            <span v-if="row.april != null && row.april != 0">{{ row.april | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusApril != 0 && row.cusApril != null"
              >{{ ((row.april / row.cusApril) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusApril == 0 || row.cusApril == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="may" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusMay != null && row.cusMay != 0">{{ row.cusMay | formatMoney  }}</span>
            <span v-if="row.cusMay == null || row.cusMay == 0">-</span
            ><br />
          </p>
          <p class="borber">
            <span v-if="row.may != null && row.may != 0">{{ row.may | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusMay != 0 && row.cusMay != null">{{ ((row.may / row.cusMay) * 100).toFixed(4) }}%</span>
            <span v-if="row.cusMay == 0 || row.cusMay == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="june" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusJune != null && row.cusJune != 0">{{ row.cusJune | formatMoney  }}</span>
            <span v-if="row.cusJune == null || row.cusJune == 0">-</span>
          </p>
          <p class="borber">
            <span v-if="row.june != null && row.june != 0">{{ row.june | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusJune != 0 && row.cusJune != null"
              >{{ ((row.june / row.cusJune) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusJune == 0 || row.cusJune == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="july" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusJuly != null && row.cusJune != 0">{{ row.cusJuly | formatMoney  }}</span>
            <span v-if="row.cusJuly == null || row.cusJuly == 0">-</span
            ><br />
          </p>
          <p class="borber">
            <span v-if="row.july != null && row.july != 0">{{ row.july | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusJuly != 0 && row.cusJuly != null"
              >{{ ((row.july / row.cusJuly) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusJuly == 0 || row.cusJuly == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="august" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusAugust != null && row.cusAugust != 0">{{ row.cusAugust | formatMoney  }}</span>
            <span v-if="row.cusAugust == null || row.cusAugust == 0">-</span
            ><br />
          </p>
          <p class="borber">
            <span v-if="row.august != null && row.august != 0">{{ row.august | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusAugust != 0 && row.cusAugust != null"
              >{{ ((row.august / row.cusAugust) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusAugust == 0 || row.cusAugust == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="september" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusSeptember != null && row.cusSeptember != 0">{{ row.cusSeptember | formatMoney  }}</span>
            <span v-if="row.cusSeptember == null || row.cusSeptember == 0">-</span
            ><br />
          </p>
          <p class="borber">
            <span v-if="row.september != null && row.september != 0">{{ row.september | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusSeptember != 0 && row.cusSeptember != null"
              >{{ ((row.september / row.cusSeptember) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusSeptember == 0 || row.cusSeptember == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="october" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusOctober != null && row.cusOctober != 0">{{ row.cusOctober | formatMoney  }}</span>
            <span v-if="row.cusOctober == null || row.cusOctober == 0">-</span
            ><br />
          </p>
          <p class="borber">
            <span v-if="row.october != null && row.october != 0">{{ row.october | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusOctober != 0 && row.cusOctober != null"
              >{{ ((row.october / row.cusOctober) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusOctober == 0 || row.cusOctober == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="november" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusNovember != null && row.cusNovember != 0">{{ row.cusNovember | formatMoney  }}</span>
            <span v-if="row.cusNovember == null || row.cusNovember == 0">-</span
            ><br />
          </p>
          <p class="borber">
            <span v-if="row.november != null && row.november != 0">{{ row.november | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusNovember != 0 && row.cusNovember != null"
              >{{ ((row.november / row.cusNovember) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusNovember == 0 || row.cusNovember == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
        <template slot="december" slot-scope="text, row">
          <p class="borber">
            <span v-if="row.cusDecember != null && row.cusDecember != 0">{{ row.cusDecember | formatMoney  }}</span>
            <span v-if="row.cusDecember == null || row.cusDecember == 0">-</span
            ><br />
          </p>
          <p class="borber">
            <span v-if="row.december != null && row.december != 0">{{ row.december | formatMoney  }} </span>
            <span v-else> - </span>
          </p>
          <p class="borber-bnt">
            <span v-if="row.cusDecember != 0 && row.cusDecember != null"
              >{{ ((row.december / row.cusDecember) * 100).toFixed(4) }}%</span
            >
            <span v-if="row.cusDecember == 0 || row.cusDecember == null">{{ (0).toFixed(4) }}%</span>
          </p>
        </template>
      </a-table>
      <CusChart :option="charts" :id="'1'" ref="cusChart"></CusChart>
    </div>
  </a-card>
</template>

<script>
import {CusColumns, FirmColumns} from './components/colums.js'
import CusChart from './components/CusChart'
import { checkPermission } from '@/utils/permissions'
import { baseURL } from '@/utils/request'

const title = {
  text: '各个事务所交易额达成情况',
}
const xAxis = {
  categories: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
}
const legend = {
  layout: 'horizontal',
  verticalAlign: 'bottom',
  borderWidth: 0,
}
export default {
  data() {
    return {
      columns: FirmColumns,
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      chartList: [],
      charts: {
        series: [
          {
            type: 'column',
            name: '当月销售目标',
            data: [],
          },
          {
            type: 'column',
            name: '当月实际金额',
            data: [],
          },
          {
            type: 'spline',
            name: '达成率',
            data: [],
          },
        ],
        xAxis: xAxis,
        legend: legend,
        title: title,
        colors: ['#FF9933', 'green', '#00FF00'],
      },
      page: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
        firmName: '',
        type: 2
      },
      tableLoading: false,
      spinning: false,
      searchData: {},
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      uploadUrl: baseURL + '/api/customer/budget/customerBudget/customerBudgetImport',
    }
  },
  created() {
    this.getData(0)
  },
  components: {
    CusChart,
  },
  computed: {
    getCusIds() {
      return { cusIds: this.selectedRowKeys, month: this.searchData.month }
    },
  },
  methods: {
    upProductChange(info) {
      this.tableLoading = true
      let hint = info.file.response
      if (hint.code == 500) {
        this.tableLoading = false
        this.$message.error(hint.message)
      } else if (hint.code == 200) {
        this.tableLoading = false
        this.$message.success(hint.message)
        this.getData()
      }
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    downLoadDone() {
      this.spinning = false
    },
    startDownLoad() {
      this.spinning = true
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.chartList = [
        this.selectedRows[0].january,
        this.selectedRows[0].february,
        this.selectedRows[0].march,
        this.selectedRows[0].april,
        this.selectedRows[0].may,
        this.selectedRows[0].june,
        this.selectedRows[0].july,
        this.selectedRows[0].august,
        this.selectedRows[0].september,
        this.selectedRows[0].october,
        this.selectedRows[0].november,
        this.selectedRows[0].december,
      ]
      let budget = [
        this.selectedRows[0].cusJanuary,
        this.selectedRows[0].cusFebruary,
        this.selectedRows[0].cusMarch,
        this.selectedRows[0].cusApril,
        this.selectedRows[0].cusMay,
        this.selectedRows[0].cusJune,
        this.selectedRows[0].cusJuly,
        this.selectedRows[0].cusAugust,
        this.selectedRows[0].cusSeptember,
        this.selectedRows[0].cusOctober,
        this.selectedRows[0].cusNovember,
        this.selectedRows[0].cusDecember,
      ]
      let achieving = [
        this.selectedRows[0].cusJanuary == 0 ? 0 : this.selectedRows[0].january / this.selectedRows[0].cusJanuary,
        this.selectedRows[0].cusFebruary == 0 ? 0 : this.selectedRows[0].february / this.selectedRows[0].cusFebruary,
        this.selectedRows[0].cusMarch == 0 ? 0 : this.selectedRows[0].march / this.selectedRows[0].cusMarch,
        this.selectedRows[0].cusApril == 0 ? 0 : this.selectedRows[0].april / this.selectedRows[0].cusApril,
        this.selectedRows[0].cusMay == 0 ? 0 : this.selectedRows[0].may / this.selectedRows[0].cusMay,
        this.selectedRows[0].cusJune == 0 ? 0 : this.selectedRows[0].june / this.selectedRows[0].cusJune,
        this.selectedRows[0].cusJuly == 0 ? 0 : this.selectedRows[0].july / this.selectedRows[0].cusJuly,
        this.selectedRows[0].cusAugust == 0 ? 0 : this.selectedRows[0].august / this.selectedRows[0].cusAugust,
        this.selectedRows[0].cusSeptember == 0 ? 0 : this.selectedRows[0].september / this.selectedRows[0].cusSeptember,
        this.selectedRows[0].cusOctober == 0 ? 0 : this.selectedRows[0].october / this.selectedRows[0].cusOctober,
        this.selectedRows[0].cusNovember == 0 ? 0 : this.selectedRows[0].november / this.selectedRows[0].cusNovember,
        this.selectedRows[0].cusDecember == 0 ? 0 : this.selectedRows[0].december / this.selectedRows[0].cusDecember,
      ]
      this.$set(this.charts.series[1], 'data', this.chartList)
      this.$set(this.charts.series[0], 'data', budget)
      this.$set(this.charts.series[2], 'data', achieving)
      this.$refs.cusChart.getCharts()
    },

    getData(type) {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      if (type == 1) {
        this.searchData = {}
        this.page = {
          pageSize: 10,
          pageNumber: 1,
          total: 0,
          firmName: '',
          type: 2
        }
      }
      this.axios.get('/api/base/system/report/listTurnoverSales', {params: this.page}).then((res) => {
        if (res.code == 200) {
          this.tableData = res.body.records
          this.page.total = res.body.total
          this.chartList = [
            this.tableData[0].january,
            this.tableData[0].february,
            this.tableData[0].march,
            this.tableData[0].april,
            this.tableData[0].may,
            this.tableData[0].june,
            this.tableData[0].july,
            this.tableData[0].august,
            this.tableData[0].september,
            this.tableData[0].october,
            this.tableData[0].november,
            this.tableData[0].december,
          ]

          this.$set(this.charts.series[1], 'data', this.chartList)
          this.$refs.cusChart.getCharts()
        }
        this.tableLoading = false
      })
    },
    // getData(type) {
    //   this.selectedRowKeys = []
    //   this.selectedRows = []
    //   if (type == 1) {
    //     this.searchData = {}
    //     this.page = {
    //       pageSize: 10,
    //       pageNumber: 1,
    //       total: 0,
    //       fname: '',
    //     }
    //   }
    //
    //
    //     this.tableData = [
    //       {
    //         achievementRate: null,
    //         april: 2,
    //         august: 10,
    //         category: null,
    //         bearingNum: null,
    //         cusApril: null,
    //         cusAugust: null,
    //         cusDecember: null,
    //         cusFebruary: null,
    //         cusJanuary: 60,
    //         cusJuly: null,
    //         cusJune: null,
    //         cusMarch: null,
    //         cusMay: null,
    //         cusNovember: null,
    //         cusOctober: null,
    //         cusSeptember: null,
    //         cusSubtotal: 10,
    //         december: 0,
    //         february: 0,
    //         id: 922,
    //         ids: null,
    //         january: 10,
    //         july: 10,
    //         june: 10,
    //         managerName: null,
    //         march: 20,
    //         matchingNum: null,
    //         may: 50,
    //         november: 2,
    //         october: -100,
    //         september: 0,
    //         singlePackageNum: null,
    //         subtotal: 0,
    //         title: '福州事务所',
    //         totalPrice: null,
    //       },
    //       {
    //         achievementRate: null,
    //         april: 2,
    //         august: 10,
    //         category: null,
    //         bearingNum: null,
    //         cusApril: null,
    //         cusAugust: null,
    //         cusDecember: null,
    //         cusFebruary: null,
    //         cusJanuary: 60,
    //         cusJuly: null,
    //         cusJune: null,
    //         cusMarch: null,
    //         cusMay: null,
    //         cusNovember: null,
    //         cusOctober: null,
    //         cusSeptember: null,
    //         cusSubtotal: 10,
    //         december: 0,
    //         february: 0,
    //         id: 923,
    //         ids: null,
    //         january: 10,
    //         july: 10,
    //         june: 10,
    //         managerName: null,
    //         march: 20,
    //         matchingNum: null,
    //         may: 50,
    //         november: 2,
    //         october: -100,
    //         september: 0,
    //         singlePackageNum: null,
    //         subtotal: 0,
    //         title: '太原事务所',
    //         totalPrice: null,
    //       },
    //       {
    //         achievementRate: null,
    //         april: 2,
    //         august: 10,
    //         category: null,
    //         bearingNum: null,
    //         cusApril: null,
    //         cusAugust: null,
    //         cusDecember: null,
    //         cusFebruary: null,
    //         cusJanuary: 60,
    //         cusJuly: null,
    //         cusJune: null,
    //         cusMarch: null,
    //         cusMay: null,
    //         cusNovember: null,
    //         cusOctober: null,
    //         cusSeptember: null,
    //         cusSubtotal: 10,
    //         december: 0,
    //         february: 0,
    //         id: 924,
    //         ids: null,
    //         january: 10,
    //         july: 10,
    //         june: 10,
    //         managerName: null,
    //         march: 20,
    //         matchingNum: null,
    //         may: 50,
    //         november: 2,
    //         october: -100,
    //         september: 0,
    //         singlePackageNum: null,
    //         subtotal: 0,
    //         title: '珠海事务所',
    //         totalPrice: null,
    //       },
    //       {
    //         achievementRate: null,
    //         april: 2,
    //         august: 10,
    //         category: null,
    //         bearingNum: null,
    //         cusApril: null,
    //         cusAugust: null,
    //         cusDecember: null,
    //         cusFebruary: null,
    //         cusJanuary: 60,
    //         cusJuly: null,
    //         cusJune: null,
    //         cusMarch: null,
    //         cusMay: null,
    //         cusNovember: null,
    //         cusOctober: null,
    //         cusSeptember: null,
    //         cusSubtotal: 10,
    //         december: 0,
    //         february: 0,
    //         id: 925,
    //         ids: null,
    //         january: 10,
    //         july: 10,
    //         june: 10,
    //         managerName: null,
    //         march: 20,
    //         matchingNum: null,
    //         may: 50,
    //         november: 2,
    //         october: -100,
    //         september: 0,
    //         singlePackageNum: null,
    //         subtotal: 0,
    //         title: '南昌事务所',
    //         totalPrice: null,
    //       },
    //     ]
    //
    //     this.chartList = [
    //       this.tableData[0].january,
    //       this.tableData[0].february,
    //       this.tableData[0].march,
    //       this.tableData[0].april,
    //       this.tableData[0].may,
    //       this.tableData[0].june,
    //       this.tableData[0].july,
    //       this.tableData[0].august,
    //       this.tableData[0].september,
    //       this.tableData[0].october,
    //       this.tableData[0].november,
    //       this.tableData[0].december,
    //     ]
    //
    //     this.$set(this.charts.series[0], 'data', this.chartList)
    //     this.$refs.cusChart.getCharts()
    //
    // },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getData(0)
    },
  },
}
</script>

<style lang="scss">
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}

::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}

.enzosp {
  //color: #b9b9b9;
  font-size: 12px;
}

.borber {
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
}

.borber-bnt {
  margin: 0;
  bottom: 0;
  top: 10px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 0 0 5px;
  overflow-wrap: break-word;
}
</style>